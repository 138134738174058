import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import Banner from 'components/Banner'
import Features from 'components/Features'
import Examples from 'components/Examples'
import Contacts from 'components/Contacts'
import FeedbackButton from 'components/FeedbackButton'
import Content from 'components/Content'
import FAQ from 'components/FAQ'
import Img from 'gatsby-image'
import bem from 'bem'
import css from './ParsingAds.module.scss'

const b = bem.sceneParsingAds(css)

const ParsingAds = ({ images }) => {
	const pictures = {}
	images.allFile.edges.forEach(({ node }) => {
		pictures[node.name] = node.childImageSharp.fluid
	})
	return (
		<Layout className={b()}>
			<SEO
				title="Парсинг досок объявлений — «Парсик»"
				description="Закажите парсинг объявлений. Получите базу Excel с объявлениями, телефонами, ценами Авито, Циан, Авто.ру и др."
				keywords="парсинг, заказ, база, excel, доска, объявление, цены, телефоны, email"
			/>
			<Banner
				title="Парсинг объявлений"
				description="&laquo;Горячие&raquo; клиенты для вашего бизнеса"
				image={{ name: 'home', width: 506, height: 392 }}
			>
				<FeedbackButton theme="light" size="lg">
					Заказать парсинг
				</FeedbackButton>
			</Banner>
			<Sector>
				<Grid fluid className="mb-xl">
					<Row>
						<Col lgOffset={1} lg={10} className="text-center">
							<p className="fs-h4">
								Соберите базу из&nbsp;сотен тысяч телефонов и&nbsp;email-адресов заинтересованных потенциальных клиентов
							</p>
						</Col>
					</Row>
				</Grid>
				<div className="mb-xl">
					<Features
						columns={3}
						isSector={false}
						data={[
							{
								url: '/',
								icon: 'checkBest',
								title: 'Изучение рынка',
								description: 'Анализ и&nbsp;поиск самых выгодных предложений для вашего бизнеса',
							},
							{
								url: '/',
								icon: 'automatic',
								title: 'Автоматизация работы',
								description: 'Использование данных в&nbsp;ваших бизнес-процессах для автоматизации ручной работы',
							},
							{
								url: '/',
								icon: 'customers',
								title: 'Увеличение клиентов',
								description: 'Предлагайте массово свои услуги потенциальным клиентам',
							},
						]}
					/>
				</div>
				<div className="text-center">
					<FeedbackButton theme="primary" size="lg">
						Узнать стоимость
					</FeedbackButton>
				</div>
			</Sector>
			<Sector color="blue" title="Закажите парсинг объявлений" isGradient titleCentered>
				<div className="fs-h4 text-center">
					<p>
						Мы&nbsp;спарсим объявления для вас в&nbsp;любом количестве и&nbsp;выгрузим все необходимые вам данные
						в&nbsp;Excel файл. Вы&nbsp;сможете регулярно получать его и&nbsp;использовать эту ценную информацию
						в&nbsp;вашем бизнесе
					</p>
				</div>
			</Sector>
			<Sector withoutContent>
				<Content>
					<Grid fluid className="mb-lg">
						<Row>
							<Col lgOffset={1} lg={10} className="text-center">
								<h2 className="mb">Пример данных для парсинга</h2>
								<p className="fs-h4">
									Любые общедоступные данные объявления, например:
									<br />
									адрес, цена, телефон, название, просмотры, дата публикации, тип продажи, имя продавца, рейтинг,
									фотографии, описание, характеристики
								</p>
							</Col>
						</Row>
					</Grid>
				</Content>
				<div className={b('screen-bg', 'mb-xl')}>
					<Content>
						<Img fluid={pictures['ob-screen']} alt="Парсинг объявления со страницы доски объявлений" />
					</Content>
				</div>
				<Content>
					<Grid fluid className="mb-lg">
						<Row>
							<Col lgOffset={1} lg={10} className="text-center">
								<h2 className="mb">Пример результата парсинга</h2>
								<p className="fs-h4">
									Excel файл с&nbsp;данными объявлений в&nbsp;табличном формате для дальнейшего использования
								</p>
							</Col>
						</Row>
					</Grid>
					<Img fluid={pictures['ob-excel']} alt="Excel файл с данными парсинга объявлений" />
				</Content>
			</Sector>
			<Examples />
			<Sector title="Вопросы и ответы">
				<div className="mb-lg">
					<FAQ
						isNumbers={true}
						items={[
							{
								title: 'Что такое парсинг объявлений?',
								description: (
									<>
										<p>
											<strong>Парсинг объявлений</strong>&nbsp;&mdash; это отличный способ получить нужную вам
											информацию с&nbsp;бесплатных и&nbsp;платных досок объявлений, электронных торговых площадок,
											сервисов электронной коммерции и&nbsp;других платформ купли-продажи.
										</p>
										<p>
											Такие данные обычно содержат описания объявлений, номера телефонов, фотографии, местоположение
											продавца и&nbsp;другую характерную объявлениям информацию.
										</p>
									</>
								),
							},
							{
								title: 'Сколько стоит парсинг объявлений?',
								description: (
									<>
										<p>
											Средняя стоимость парсинга сайта равна 5000&nbsp;руб, однако, в&nbsp;случае досок объявлений, цена
											зачастую выше цены парсинга обычного сайта. Это связано тем, что парсинг заказывается
											в&nbsp;основном для популярных досок объявлений, которые имеют огромные объемы информации
											и&nbsp;защиту разного уровня сложности. Но, часто людям нужно спарсить только выборочную часть
											сайта, например, объявления из&nbsp;категории &laquo;автомобили&raquo; или объявления
											определенного региона. В&nbsp;этом случае цена стандартная и&nbsp;приемлема для большинства наших
											клиентов.
										</p>
										<p>
											Чтобы узнать цену, напишите нам через контактную форму, какую доску объявлений вам нужно спарсить
											и&nbsp;какую информацию с&nbsp;сайта вы&nbsp;хотите получить. Мы&nbsp;рассчитаем и&nbsp;скажем вам
											точную стоимость.
										</p>
									</>
								),
							},
							{
								title: 'Какие данные можно парсить с&nbsp;доски объявлений?',
								description: (
									<>
										<p className="no-last">Мы&nbsp;сможем спарсить любые открытые данные сайта объявлений, например:</p>
										<ol>
											<li>Название объявления</li>
											<li>Контакты, например адрес электронной почты, телефон, географическое местоположение</li>
											<li>Категории и&nbsp;подкатегории объявления</li>
											<li>Цена за&nbsp;товар или услугу</li>
											<li>Полное описание с&nbsp;характеристиками и&nbsp;опциями</li>
											<li>Фотографии, видео, различные вложения</li>
											<li>Информация о&nbsp;контактном лице или компании предоставляющего товар или услугу</li>
											<li>
												Общие сведения о&nbsp;количестве просмотров объявления на&nbsp;сайте, активности продавца,
												времени публикации, данных отчетов системы и&nbsp;прочее
											</li>
										</ol>
									</>
								),
							},
							{
								title: 'Зачем нужен парсинг объявлений?',
								description: (
									<>
										<p className="no-last">
											Поначалу может показаться, что парсинг сайтов с&nbsp;объявлениями не&nbsp;имеет ничего особенного,
											ведь всю информацию можно легко получить на&nbsp;тех&nbsp;же самых досках объявлений. Однако, это
											большое заблуждение. Интерфейс этих площадок не&nbsp;предоставляет вам даже&nbsp;10% тех
											возможностей, которые можно получить от&nbsp;парсинга. Имея собственную базу объявлений, можно:
										</p>
										<ol>
											<li>
												Использовать различные фильтры и&nbsp;сортировки. Например, недвижимость можно отобрать
												по&nbsp;расположению, типу помещения, количеству комнат, цене, дате и&nbsp;т.д. Таким образом
												можно найти самые выгодные предложения
											</li>
											<li>
												Получить номера телефонов и&nbsp;email-адреса определенной категории объявлений
												и&nbsp;использовать их&nbsp;для коммерческих целей, например, для массовой рассылки
												коммерческого предложения или холодных звонков с&nbsp;предложением ваших услуг
											</li>
											<li>
												Использовать данные полученные при парсинге на&nbsp;своих сайтах. Простейшим примером можно
												привести сайт агентства недвижимости с&nbsp;объявлениями в&nbsp;вашем регионе. Так&nbsp;же
												и&nbsp;в&nbsp;более сложных проектах. Если вы&nbsp;предоставляете услуги для бизнеса
												и&nbsp;хотите сделать b2b-систему для предоставления вашим клиентам аналитических данных,
												то&nbsp;автоматическая работа с&nbsp;данными парсинга является неотъемлемой частью этого
												процесса
											</li>
											<li>Импортировать и&nbsp;использовать в&nbsp;различных CMS</li>
											<li>
												Получать данные о&nbsp;спросе и&nbsp;падении цен, анализировать спрос и&nbsp;предложение
												на&nbsp;рынке, рассчитывать среднюю цену объявлений. Парсинг даст вам бесценную информацию,
												которую можно использовать во&nbsp;многих направлениях
											</li>
										</ol>
									</>
								),
							},
							{
								title: 'Кому нужен парсинг объявлений?',
								description: (
									<>
										<p>
											Парсинг досок объявлений применяется в&nbsp;самых разных направлениях. Это могут быть как
											агентства недвижимости, так и&nbsp;авто-дилеры, работодатели, веб-мастера. Данные, полученные
											с&nbsp;помощью парсинга имеют всю необходимую информацию о&nbsp;потенциальных клиентах.
										</p>
										<p className="no-last">Парсинг будет полезен:</p>
										<ol>
											<li>
												Предпринимателям, для получения &laquo;горячих&raquo; клиентов с&nbsp;целью предоставления услуг
											</li>
											<li>Веб-мастерам, для начального заполнения контентом собственных досок объявлений</li>
											<li>
												Агентствам недвижимости для автоматизации ручной работы по&nbsp;сортировке, сравнению, отбору
												предложений
											</li>
											<li>Работодателям для поиска и&nbsp;подбора персонала</li>
											<li>Посредникам, зарабатывающим на&nbsp;перепродаже товаров и&nbsp;услуг</li>
											<li>Инвесторам, для анализа спроса и&nbsp;предложения на&nbsp;рынке</li>
											<li>Бизнесменам, работающим с&nbsp;партнерскими программами</li>
										</ol>
									</>
								),
							},
							{
								title: 'Как использовать данные парсинга объявлений?',
								description: (
									<>
										<p>
											Данные, полученные при помощи парсинга, будут сохранены в&nbsp;Excel файле. Этот формат отлично
											подходит для хранения большого объема информации, например сотен тысяч строк и&nbsp;его можно
											использовать как полноценную базу данных.
										</p>
										<p>
											Объявления удобно анализировать как автоматически, так и&nbsp;вручную, так как они имеют табличный
											формат. Сегодня существует много готовых технических решений для работы с&nbsp;Excel файлами,
											например плагины для сайтов и&nbsp;интернет-магазинов, позволяющие импортировать полученный
											парсингом Excel файл и&nbsp;создавать на&nbsp;их&nbsp;основе товары или CRM-системы,
											в&nbsp;которые загружаются телефоны и&nbsp;адреса потенциальных клиентов.
										</p>
									</>
								),
							},
							{
								title: 'Сколько времени нужно на&nbsp;создание парсера объявлений?',
								description: (
									<>
										<p>
											Создание парсера сайта занимает в&nbsp;среднем от&nbsp;3&nbsp;до&nbsp;7&nbsp;дней.
											В&nbsp;зависимости от&nbsp;того, какую доску объявлений нужно парсить и&nbsp;какие требования
											у&nbsp;клиента, срок написания парсера может быть как меньше, так и&nbsp;чуть больше.
											До&nbsp;начала работ мы&nbsp;анализируем сайт донор, рассчитываем время и&nbsp;говорим клиенту.
										</p>
									</>
								),
							},
							{
								title: 'Как долго парсятся доски объявлений?',
								description: (
									<>
										<p>
											Популярные доски объявлений имеют сотни тысяч и&nbsp;даже миллионы страниц. Например Циан содержит
											больше 3&nbsp;миллионов объектов недвижимости, а&nbsp;Авито больше 30&nbsp;миллионов объявлений.
											На&nbsp;парсинг такого объема данных нужно много времени. Но, часто клиентам нужна только часть
											этих данных, например, объявления определенного региона или выборочной категории и&nbsp;объем
											данных существенно снижается. Поэтому, на&nbsp;практике, парсинг успевает выполняться
											в&nbsp;течение суток.
										</p>
									</>
								),
							},
							{
								title: 'Какие доски объявлений лучше всего парсить?',
								description: (
									<>
										<p>
											Часто компании, имеющие желание заказать парсинг объявлений уже работают с&nbsp;некоторыми
											площадками, поэтому данного вопроса не&nbsp;возникает. Однако, если вы&nbsp;начинающий бизнесмен,
											то&nbsp;лучше выбрать для парсинга те&nbsp;доски объявлений, которые полностью будут содержать всю
											необходимую информацию для вашего бизнеса. Например, если вы&nbsp;занимаетесь недвижимостью,
											то&nbsp;таковыми могут быть популярные Циан, Авито недвижимость, Домофонд. Если автомобилями,
											то&nbsp;Авто.ру, Авито.
										</p>
										<p className="no-last">
											По&nbsp;типам объявлений, наиболее популярные и&nbsp;подходящие для парсинга можно выделить
											сделующие сайты:
										</p>
										<ol>
											<li>Недвижимость: Авито-недвижимость, Циан, Домофонд, Яндекс-недвижимость</li>
											<li>Автомобили: Авто.ру, Авито-авто, Юла-транспорт, Кар.ру, Дром</li>
											<li>Работа: HeadHunter, Авито-работа</li>
											<li>Личные вещи: Авито, Юла, OLX, Из&nbsp;рук в&nbsp;руки</li>
											<li>Любые другие бесплатные и&nbsp;платные доски объявлений</li>
										</ol>
									</>
								),
							},
							{
								title: 'Можно&nbsp;ли парсить сразу несколько досок объявлений?',
								description: (
									<>
										<p>
											Такой необходимости у&nbsp;клиентов не&nbsp;возникает, потому что на&nbsp;разных досках часто
											публикуются одинаковые объявления, но&nbsp;если вам это необходимо, то&nbsp;мы&nbsp;можем парсить
											сразу несколько сайтов. Полученные данные соберем воедино с&nbsp;фильтрацией по&nbsp;общим
											признакам или создадим два отдельных Excel файла с&nbsp;каждой доски объявлений.
										</p>
									</>
								),
							},
							{
								title: 'Выгодно&nbsp;ли парсить объявления?',
								description: (
									<>
										<p>
											В&nbsp;наше время почти во&nbsp;всех сферах предложение товаров и&nbsp;услуг гораздо превышает
											спрос. Поэтому, парсить объявления, безусловно, выгодно.
										</p>
										<p>
											Представьте, что у&nbsp;вас есть база объявлений с&nbsp;номерами телефонов и&nbsp;email адресами
											10&nbsp;тысяч потенциальных клиентов в&nbsp;вашем городе. Причем эти клиенты&nbsp;&mdash;
											&laquo;горячие&raquo;, потому что эти люди заинтересованы и&nbsp;сами оставили свои контактные
											данные с&nbsp;целью получения той или иной услуги. Теперь у&nbsp;вас есть возможность предложить
											вашу услугу всем этим людям, будь&nbsp;то автоматический холодный звонок или коммерческое
											предложение. А&nbsp;если вы&nbsp;работаете по&nbsp;всей России, то&nbsp;число возможных клиентов
											возрастает в&nbsp;десятки раз.
										</p>
										<p>
											Затраты на&nbsp;разработку парсера окупаются уже в&nbsp;первые дни работы с&nbsp;этими бесценными
											данными.
										</p>
									</>
								),
							},
							{
								title: 'Можно&nbsp;ли спарсить объявления из&nbsp;определенной категории или региона?',
								description: (
									<>
										<p>
											Конечно. И&nbsp;это необходимо, так как нет смысла платить за&nbsp;ту&nbsp;информацию, которую
											вы&nbsp;не&nbsp;будете использовать. Если ваш бизнес работает в&nbsp;определенном регионе
											и&nbsp;вы&nbsp;занимаетесь конкретной деятельностью, например, продаете шины и&nbsp;диски
											в&nbsp;Москве и&nbsp;Московской области, парсер объявлений по&nbsp;этим критериям будет
											формировать итоговые данные значительно быстрее, и, как следствие, будут более актуальны.
										</p>
									</>
								),
							},
							{
								title: 'Как вы&nbsp;парсите изображения объявлений?',
								description: (
									<>
										<p>
											При парсинге сайта собираются абсолютные URL-адреса на&nbsp;изображения и&nbsp;файлы объявлений
											и&nbsp;перечисляются через запятую в&nbsp;конечном Excel файле. URL-адреса можно использовать для
											загрузки файлов в&nbsp;ваш проект или для просмотра на&nbsp;первоисточнике.
										</p>
									</>
								),
							},
							{
								title: 'Можно&nbsp;ли изменить данные объявлений при парсинге?',
								description: (
									<>
										<p>
											Обычно данные текстов, полученных посредством парсинга сайтов, полезно менять в&nbsp;целях
											повышения уникальности и&nbsp;часто это нужно для молодых проектов. Например, для новой доски
											объявлений, которую требуется наполнить уникальной информацией для успешного старта и&nbsp;лучшей
											индексации в&nbsp;поисковых системах. Мы&nbsp;можем это сделать с&nbsp;разной долей замены
											текстов.
										</p>
									</>
								),
							},
							{
								title: 'Что нужно для заказа парсинга объявлений?',
								description: (
									<>
										<p>
											Для заказа парсинга сайта объявлений просто отправьте нам письмо на&nbsp;почту через контактную
											форму. Напишите, какую доску объявлений вы&nbsp;хотите спарсить и&nbsp;какие данные вам нужны.
											Мы&nbsp;проанализируем сайт донор, рассчитаем цену работы, сроки выполнения и&nbsp;сообщим вам.
										</p>
									</>
								),
							},
						]}
					/>
				</div>
				<div className="text-center">
					<FeedbackButton theme="primary" size="lg">
						Задать вопрос
					</FeedbackButton>
				</div>
			</Sector>
			<Contacts color="gray" />
		</Layout>
	)
}

export default ParsingAds
